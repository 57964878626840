import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => (
  <main>
    <h1>404</h1>
    <Link to='/'>Go Home</Link>
  </main>
);

export default NotFoundPage