import { auth } from '../firebase/firebase';
import { history } from '../routers/AppRouter';
import { 
  createUserWithEmailAndPassword, 
  sendEmailVerification, 
  sendPasswordResetEmail,
  signInWithEmailAndPassword, 
  signOut,
  updateProfile 
} from 'firebase/auth';

export const login = (uid) => ({
  type: 'LOGIN',
  uid
});

export const logout = () => ({
  type: 'LOGOUT'
});

export const startLogin = ({ email, password }) => {
  return (dispatch) => {
    return signInWithEmailAndPassword(auth, email, password).then(() => {
      if (auth.currentUser.emailVerified === false) {
        alert('PLEASE VERIFY YOUR EMAIL BEFORE LOGGING IN');
      }
    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      switch (errorCode) {
        case 'auth/invalid-login-credentials':
          alert('INVALID CREDENTIALS');
          break;
        // case 'auth/user-not-found':
        //   alert('THE EMAIL IS INCORRECT');
      }
      console.log(errorCode);
      console.log(errorMessage);
    });
  };
};

export const startLogout = () => {
  return (dispatch) => {
    return signOut(auth).then(() => {
      history.push('/');
    });
  };
};

export const startRegister = ({ email, name, password }) => {
  return (dispatch) => {
    return createUserWithEmailAndPassword(auth, email, password).then(() => {
      const user = auth.currentUser;
      if (user !== null) {
        updateProfile(user, {
          displayName: name
        }).then(() => {
          console.log(user.displayName);
        }).catch((error) => {
          console.log(error);
        });
        sendEmailVerification(user).then(() => {
          history.push('/login-page');
          console.log('EMAIL VERIFICATION SENT!');
        });
      }
    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode);
      console.log(errorMessage);
    });
  };
};

export const startResetPassword = ({ email }) => {
  return (dispatch) => {
    return sendPasswordResetEmail(auth, email).then(() => {
      history.push('/login-page');
    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      switch (errorCode) {
        case 'auth/user-not-found':
          alert('EMAIL NOT FOUND');
      }
      console.log(errorCode);
      console.log(errorMessage);
    });
  }
};