export const categoryOptions = [
{
  category: '-- CREATIVE ARTS CEREMONY CATEGORY - FILM --', // BROADCAST/CABLE SERIES
  formSelect: null,
  networkSelect: 'all',
  disabled: true
},
{
	category: 'Best Casting',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: false
},
{
	category: 'Best Cinematography',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: false
},
{
	category: 'Best Costume Design',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: false
},
{
	category: 'Best Editing',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: false
},
{
	category: 'Best Hair & Makeup',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: false
},
{
	category: 'Best Original Song',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: false
},
{
	category: 'Best Production Design',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: false
},
{
	category: 'Best Publicity Campaign',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: false
},
{
	category: 'Best Score',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: false
},
{
	category: 'Best Sound',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: false
},
{
	category: 'Best Stunts',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: false
},
{
	category: 'Best Visual Effects',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: false
},
{
  category: '-- CREATIVE ARTS CEREMONY CATEGORY - TV --', // CREATIVE ARTS SERIES
  formSelect: null,
  networkSelect: 'all',
  disabled: true
},
{
	category: 'Best Casting in a Limited Series or TV Movie',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: true
},
{
	category: 'Best Casting in a Series, Comedy',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: true
},
{
	category: 'Best Casting in a Series, Drama',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: true
},
{
	category: 'Best Contemporary Costumes',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: true
},
{
	category: 'Best Fantasy or Science Fiction Costumes',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: true
},
{
	category: 'Best Game Show',
	formSelect: 'series',
	networkSelect: 'all',
	disabled: true
},
{
	category: 'Best International Series',
	formSelect: 'series',
	networkSelect: 'all',
	disabled: true
},
{
	category: 'Best Guest Actor in a Series, Drama',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: true
},
{
	category: 'Best Guest Actress in a Series, Drama',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: true
},
{
	category: 'Best Guest Actor in a Series, Comedy',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: true
},
{
	category: 'Best Guest Actress in a Series, Comedy',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: true
},
{
	category: 'Best Main Title Design',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: true
},
{
	category: 'Best Original Song',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: true
},
{
	category: 'Best Period Costumes',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: true
},
{
	category: 'Best Score in a Drama Series',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: true
},
{
	category: 'Best Score in a Comedy Series',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: true
},
{
	category: 'Best Score in a Limited Series or TV Movie',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: true
},
{
	category: 'Best Short Form Series',
	formSelect: 'series',
	networkSelect: 'all',
	disabled: true
},
{
	category: 'Best Stunts',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: true
},
{
	category: 'Best Talk Show',
	formSelect: 'series',
	networkSelect: 'all',
	disabled: true
},
{
	category: 'Best Voice-Over Performance',
	formSelect: 'talent',
	networkSelect: 'all',
	disabled: true
},
{
	category: 'Best Variety Series or Variety Special',
	formSelect: 'series',
	networkSelect: 'all',
	disabled: true
},
// {
//   category: 'Best Broadcast Network or Cable Animated Series or Television Movie',
//   formSelect: 'series',
//   networkSelect: 'broadcast',
//   disabled: false
// },
// {
//   category: 'Best Broadcast Network Reality Show or Competition Series',
//   formSelect: 'series',
//   networkSelect: 'broadcast',
//   disabled: false
// },
// {
//   category: 'Best Cable Reality Show or Competition Series',
//   formSelect: 'series',
//   networkSelect: 'broadcast',
//   disabled: false
// },
// {
//   category: 'Best Broadcast Network or Cable Comedy Special',
//   formSelect: 'series',
//   networkSelect: 'broadcast',
//   disabled: false
// },
// {
//   category: 'Best Broadcast Network Series, Drama',
//   formSelect: 'series',
//   networkSelect: 'broadcast',
//   disabled: false
// },
// {
//   category: 'Best Cable Series, Drama',
//   formSelect: 'series',
//   networkSelect: 'broadcast',
//   disabled: false
// },
// {
//   category: 'Best Actor in a Broadcast Network or Cable Series, Drama',
//   formSelect: 'talent',
//   networkSelect: 'broadcast',
//   disabled: false
// },
// {
//   category: 'Best Actress in a Broadcast Network or Cable Series, Drama',
//   formSelect: 'talent',
//   networkSelect: 'broadcast',
//   disabled: false
// },
// {
//   category: 'Best Supporting Actor in a Broadcast Network or Cable Series, Drama',
//   formSelect: 'talent',
//   networkSelect: 'broadcast',
//   disabled: false
// },
// {
//   category: 'Best Supporting Actress in a Broadcast Network or Cable Series, Drama',
//   formSelect: 'talent',
//   networkSelect: 'broadcast',
//   disabled: false
// },
// {
//   category: 'Best Writing in a Broadcast Network or Cable Series, Drama',
//   formSelect: 'talent',
//   networkSelect: 'broadcast',
//   disabled: false
// },
// {
//   category: 'Best Directing in a Broadcast Network or Cable Series, Drama',
//   formSelect: 'talent',
//   networkSelect: 'broadcast',
//   disabled: false
// },
// {
//   category: 'Best Broadcast Network Series, Comedy',
//   formSelect: 'series',
//   networkSelect: 'broadcast',
//   disabled: false
// },
// {
//   category: 'Best Cable Series, Comedy',
//   formSelect: 'series',
//   networkSelect: 'broadcast',
//   disabled: false
// },
// {
//   category: 'Best Actor in a Broadcast Network or Cable Series, Comedy',
//   formSelect: 'broadcast',
//   networkSelect: 'broadcast',
//   disabled: false
// },
// {
//   category: 'Best Actress in a Broadcast Network or Cable Series, Comedy',
//   formSelect: 'talent',
//   networkSelect: 'broadcast',
//   disabled: false
// },
// {
//   category: 'Best Supporting Actor in a Broadcast Network or Cable Series, Comedy',
//   formSelect: 'talent',
//   networkSelect: 'broadcast',
//   disabled: false
// },
// {
//   category: 'Best Supporting Actress in a Broadcast Network or Cable Series, Comedy',
//   formSelect: 'talent',
//   networkSelect: 'broadcast',
//   disabled: false
// },
// {
//   category: 'Best Writing in a Broadcast Network or Cables Series, Comedy',
//   formSelect: 'talent',
//   networkSelect: 'broadcast',
//   disabled: false
// },
// {
//   category: 'Best Directing in a Broadcast Network or Cable Series, Comedy',
//   formSelect: 'talent',
//   networkSelect: 'broadcast',
//   disabled: false
// },
// {
//   category: 'Best Broadcast Network or Cable Limited or Anthology Series',
//   formSelect: 'series',
//   networkSelect: 'broadcast',
//   disabled: false
// },
// {
//   category: 'Best Broadcast Network or Cable Live-Action Television Movie',
//   formSelect: 'series',
//   networkSelect: 'broadcast',
//   disabled: false
// },
// {
// 	category: 'Best Broadcast Network or Cable Documentary TV Movie',
// 	formSelect: 'series',
// 	networkSelect: 'broadcast',
// 	disabled: false
// },
// {

// 	category: 'Best Broadcast Network or Cable Docuseries or Non-Fiction Series',
// 	formSelect: 'series',
// 	networkSelect: 'broadcast',
// 	disabled: false
// },
// {
//   category: 'Best Actor in a Broadcast Network or Cable Limited or Anthology Series or Movie',
//   formSelect: 'talent',
//   networkSelect: 'broadcast',
//   disabled: false
// },
// {
//   category: 'Best Actress in a Broadcast Network or Cable Limited or Anthology Series or Movie',
//   formSelect: 'talent',
//   networkSelect: 'broadcast',
//   disabled: false
// },
// {
//   category: 'Best Supporting Actor in a Broadcast Network or Cable Limited or Anthology Series or Movie',
//   formSelect: 'talent',
//   networkSelect: 'broadcast',
//   disabled: false
// },
// {
//   category: 'Best Supporting Actress in a Broadcast Network or Cable Limited or Anthology Series or Movie',
//   formSelect: 'talent',
//   networkSelect: 'broadcast',
//   disabled: false
// },
// {
//   category: 'Best Writing in a Broadcast Network or Cable Limited or Anthology Series or Movie',
//   formSelect: 'talent',
//   networkSelect: 'broadcast',
//   disabled: false
// },
// {
//   category: 'Best Directing in a Broadcast Network or Cable Limited or Anthology Series or Movie',
//   formSelect: 'talent',
//   networkSelect: 'broadcast',
//   disabled: false
// },
// {
//   category: '-- STREAMING CEREMONY CATEGORIES --', // STREAMING SERIES
//   formSelect: null,
//   networkSelect: 'streaming',
//   disabled: true
// },
// {
//   category: 'Best Streaming Animated Series or TV Movie',
//   formSelect: 'series',
//   networkSelect: 'streaming',
//   disabled: false
// },
// {
//   category: 'Best Streaming Comedy Special',
//   formSelect: 'series',
//   networkSelect: 'streaming',
//   disabled: false
// },
// {
//   category: 'Best Streaming Reality Show or Competition Series',
//   formSelect: 'series',
//   networkSelect: 'streaming',
//   disabled: false
// },
// {
//   category: 'Best Streaming Series, Drama',
//   formSelect: 'series',
//   networkSelect: 'streaming',
//   disabled: false
// },
// {
//   category: 'Best Actor in a Streaming Series, Drama',
//   formSelect: 'talent',
//   networkSelect: 'streaming',
//   disabled: false
// },
// {
//   category: 'Best Actress in a Streaming Series, Drama',
//   formSelect: 'talent',
//   networkSelect: 'streaming',
//   disabled: false
// },
// {
//     category: 'Best Supporting Actor in a Streaming Series, Drama',
//     formSelect: 'talent',
//     networkSelect: 'streaming',
//     disabled: false
// },
// {
//   category: 'Best Supporting Actress in a Streaming Series, Drama',
//   formSelect: 'talent',
//   networkSelect: 'streaming',
//   disabled: false
// },
// {
//   category: 'Best Writing in a Streaming Series, Drama',
//   formSelect: 'talent',
//   networkSelect: 'streaming',
//   disabled: false
// },
// {
//   category: 'Best Directing in a Streaming Series, Drama',
//   formSelect: 'talent',
//   networkSelect: 'streaming',
//   disabled: false
// },
// {
//   category: 'Best Streaming Series, Comedy',
//   formSelect: 'series',
//   networkSelect: 'streaming',
//   disabled: false
// },
// {
//   category: 'Best Actor in a Streaming Series, Comedy',
//   formSelect: 'talent',
//   networkSelect: 'streaming',
//   disabled: false
// },
// {
//   category: 'Best Actress in a Streaming Series, Comedy',
//   formSelect: 'talent',
//   networkSelect: 'streaming',
//   disabled: false
// },
// {
//   category: 'Best Supporting Actor in a Streaming Series, Comedy',
//   formSelect: 'talent',
//   networkSelect: 'streaming',
//   disabled: false
// },
// {
//   category: 'Best Supporting Actress in a Streaming Series, Comedy',
//   formSelect: 'talent',
//   networkSelect: 'streaming',
//   disabled: false
// },
// {
//   category: 'Best Writing in a Streaming Series, Comedy',
//   formSelect: 'talent',
//   networkSelect: 'streaming',
//   disabled: false
// },
// {
//   category: 'Best Directing in a Streaming Series, Comedy',
//   formSelect: 'talent',
//   networkSelect: 'streaming',
//   disabled: false
// },
// {
//   category: 'Best Streaming Limited or Anthology Series',
//   formSelect: 'series',
//   networkSelect: 'streaming',
//   disabled: false
// },
// {
//   category: 'Best Streaming Movie',
//   formSelect: 'series',
//   networkSelect: 'streaming',
//   disabled: false
// },
// {
// 	category: 'Best Streaming Documentary Movie',
// 	formSelect: 'series',
// 	networkSelect: 'streaming',
// 	disabled: false
// },
// {
// 	category: 'Best Streaming Docuseries or Non-Fiction Series',
// 	formSelect: 'series',
// 	networkSelect: 'streaming',
// 	disabled: false
// },
// {
//   category: 'Best Actor in a Streaming Limited or Anthology Series or Movie',
//   formSelect: 'talent',
//   networkSelect: 'streaming',
//   disabled: false
// },
// {
//   category: 'Best Actress in a Streaming Limited or Anthology Series or Movie',
//   formSelect: 'talent',
//   networkSelect: 'streaming',
//   disabled: false
// },
// {
//   category: 'Best Supporting Actor in a Streaming Limited or Anthology Series or Movie',
//   formSelect: 'talent',
//   networkSelect: 'streaming',
//   disabled: false
// },
// {
//   category: 'Best Supporting Actress in a Streaming Limited or Anthology Series or Movie',
//   formSelect: 'talent',
//   networkSelect: 'streaming',
//   disabled: false
// },
// {
//   category: 'Best Writing in a Streaming Limited or Anthology Series or Movie',
//   formSelect: 'talent',
//   networkSelect: 'streaming',
//   disabled: false
// },
// {
//   category: 'Best Directing in a Streaming Limited or Anthology Series or Movie',
//   formSelect: 'talent',
//   networkSelect: 'streaming',
//   disabled: false
// }
];
export const allNetworks = [
  // {
  //   network: '-- BROADCAST NETWORK --',
  //   disabled: true
  // },
  {
    network: '20th Century Studios',
    disabled: false
  },
  {
    network: 'A24',
    disabled: false
  },
  {
    network: 'Amazon/MGM',
    disabled: false
  },
  {
    network: 'Apple TV+',
    disabled: false
  },
  {
    network: 'Focus Features',
    disabled: false
  },
  {
    network: 'IFC Films',
    disabled: false
  },
  {
    network: 'Lionsgate',
    disabled: false
  },
  {
    network: 'MAX',
    disabled: false
  },
  {
    network: 'Magnolia Pictures',
    disabled: false
  },
  {
    network: 'Marvel Studios',
    disabled: false
  },
  {
    network: 'NEON',
    disabled: false
  },
  {
    network: 'Netflix',
    disabled: false
  },
  {
    network: 'Other',
    disabled: false
  },
  {
    network: 'Paramount+',
    disabled: false
  },
  {
    network: 'Paramount Pictures',
    disabled: false
  },
  {
    network: 'Pixar',
    disabled: false
  },
  {
    network: 'Searchlight Pictures',
    disabled: false
  },
  {
    network: 'Sony Pictures',
    disabled: false
  },
  {
    network: 'Sony Pictures Classics',
    disabled: false
  },
  {
    network: 'Universal Pictures',
    disabled: false
  },
  {
    network: 'Walt Disney Pictures',
    disabled: false
  },
  {
    network: 'Warner Bros.',
    disabled: false
  },
  // {
  //   network: 'ABC',
  //   disabled: false
  // },
  // {
  //   network: 'CBS',
  //   disabled: false
  // },
  // {
  //   network: 'CW',
  //   disabled: false
  // },
  // {
  //   network: 'FOX',
  //   disabled: false
  // },
  // {
  //   network: 'NBC',
  //   disabled: false
  // },
  // {
  //   network: 'PBS',
  //   disabled: false
  // },
  // {
  //   network: '-- CABLE NETWORK --',
  //   disabled: true
  // },
  // {
  //   network: 'A&E',
  //   disabled: false
  // },
  // {
  //   network: 'Acorn TV',
  //   disabled: false
  // },
  // {
  //   network: 'Adult Swim',
  //   disabled: false
  // },
  // {
  //   network: 'AMC',
  //   disabled: false
  // },
  // {
  //   network: 'Animal Planet',
  //   disabled: false
  // },
  // {
  //   network: 'BBC America',
  //   disabled: false
  // },
  // {
  //   network: 'BET Networks',
  //   disabled: false
  // },
  // {
  //   network: 'Bravo',
  //   disabled: false
  // },
  // {
  //   network: 'Cartoon Network',
  //   disabled: false
  // },
  // {
  //   network: 'Cinemax',
  //   disabled: false
  // },
  // {
  //   network: 'CMT',
  //   disabled: false
  // },
  // {
  //   network: 'Comedy Central',
  //   disabled: false
  // },
  // {
  //   network: 'Discovery Channel',
  //   disabled: false
  // },
  // {
  //   network: 'Disney Channel',
  //   disabled: false
  // },
  // {
  //   network: 'Disney XD',
  //   disabled: false
  // },
  // {
  //   network: 'DIY Network',
  //   disabled: false
  // },
  // {
  //   network: 'E!',
  //   disabled: false
  // },
  // {
  //   network: 'El Rey Network',
  //   disabled: false
  // },
  // {
  //   network: 'Epix',
  //   disabled: false
  // },
  // {
  //   network: 'ESPN',
  //   disabled: false
  // },
  // {
  //   network: 'Food Network',
  //   disabled: false
  // },
  // {
  //   network: 'Freeform',
  //   disabled: false
  // },
  // {
  //   network: 'Fuse',
  //   disabled: false
  // },
  // {
  //   network: 'FX Networks',
  //   disabled: false
  // },
  // {
  //   network: 'Hallmark Channel',
  //   disabled: false
  // },
  // {
  //   network: 'HBO',
  //   disabled: false
  // },
  // {
  //   network: 'HGTV',
  //   disabled: false
  // },
  // {
  //   network: 'History',
  //   disabled: false
  // },
  // {
  //   network: 'IFC',
  //   disabled: false
  // },
  // {
  //   network: 'Investigation Discovery',
  //   disabled: false
  // },
  // {
  //   network: 'Lifetime',
  //   disabled: false
  // },
  // {
  //   network: 'Logo',
  //   disabled: false
  // },
  // {
  //   network: 'MTV',
  //   disabled: false
  // },
  // {
  //   network: 'National Geographic',
  //   disabled: false
  // },
  // {
  //   network: 'Nickelodeon',
  //   disabled: false
  // },
  // {
  //   network: 'Other',
  //   disabled: false
  // },
  // {
  //   network: 'OWN',
  //   disabled: false
  // },
  // {
  //   network: 'Oxygen',
  //   disabled: false
  // },
  // {
  //   network: 'Paramount Network',
  //   disabled: false
  // },
  // {
  //   network: 'Pivot',
  //   disabled: false
  // },
  // {
  //   network: 'Pop TV',
  //   disabled: false
  // },
  // {
  //   network: 'Reelz Channel',
  //   disabled: false
  // },
  // {
  //   network: 'Showtime',
  //   disabled: false
  // },
  // {
  //   network: 'Smithsonian',
  //   disabled: false
  // },
  // {
  //   network: 'Spectrum',
  //   disabled: false
  // },
  // {
  //   network: 'Starz',
  //   disabled: false
  // },
  // {
  //   network: 'SundanceTV',
  //   disabled: false
  // },
  // {
  //   network: 'SYFY',
  //   disabled: false
  // },
  // {
  //   network: 'TBS',
  //   disabled: false
  // },
  // {
  //   network: 'Telemundo',
  //   disabled: false
  // },
  // {
  //   network: 'TLC',
  //   disabled: false
  // },
  // {
  //   network: 'TNT',
  //   disabled: false
  // },
  // {
  //   network: 'Travel Channel',
  //   disabled: false
  // },
  // {
  //   network: 'TruTV',
  //   disabled: false
  // },
  // {
  //   network: 'TV Land',
  //   disabled: false
  // },
  // {
  //   network: 'Univision',
  //   disabled: false
  // },
  // {
  //   network: 'USA',
  //   disabled: false
  // },
  // {
  //   network: 'VH1',
  //   disabled: false
  // },
  // {
  //   network: 'Viceland',
  //   disabled: false
  // },
  // {
  //   network: 'WE tv',
  //   disabled: false
  // },
  // {
  //   network: 'WWE Network',
  //   disabled: false
  // },
  // {
  //   network: '-- STREAMING PLATFORMS --',
  //   disabled: true
  // },	
  // {
	// 	network: 'Acorn TV',
	// 	disabled: false
	// },
	// {
	// 	network: 'ALLBLK',
	// 	disabled: false
	// },
	// {
	// 	network: 'Amazon Prime',
	// 	disabled: false
	// },
	// {
	// 	network: 'AMC+',
	// 	disabled: false
	// },
	// {
	// 	network: 'Apple TV+',
	// 	disabled: false
	// },
	// {
	// 	network: 'BET+',
	// 	disabled: false
	// },
	// {
	// 	network: 'Crunchyroll',
	// 	disabled: false
	// },
	// {
	// 	network: 'Discovery+',
	// 	disabled: false
	// },
	// {
	// 	network: 'Disney+',
	// 	disabled: false
	// },
	// {
	// 	network: 'ESPN+',
	// 	disabled: false
	// },
	// {
	// 	network: 'Facebook Watch',
	// 	disabled: false
	// },
	// {
	// 	network: 'Freevee',
	// 	disabled: false
	// },
	// {
	// 	network: 'HBO Max',
	// 	disabled: false
	// },
	// {
	// 	network: 'Hulu',
	// 	disabled: false
	// },
	// {
	// 	network: 'IMDb',
	// 	disabled: false
	// },
	// {
	// 	network: 'Netflix',
	// 	disabled: false
	// },
	// {
	// 	network: 'Pantaya',
	// 	disabled: false
	// },
	// {
	// 	network: 'Paramount+',
	// 	disabled: false
	// },
	// {
	// 	network: 'Peacock',
	// 	disabled: false
	// },
	// {
	// 	network: 'Pluto TV',
	// 	disabled: false
	// },
	// {
	// 	network: 'Roku',
	// 	disabled: false
	// },
	// {
	// 	network: 'Shudder',
	// 	disabled: false
	// },
	// {
	// 	network: 'Sundance Now',
	// 	disabled: false
	// },
	// {
	// 	network: 'WE tv+',
	// 	disabled: false
	// },
	// {
	// 	network: 'YouTube',
	// 	disabled: false
	// }
];
export const streamingNetworks = [
  'Acorn TV',
  'ALLBLK',
  'Amazon Prime',
  'AMC+',
  'Apple TV+',
  'BET+',
  'Crunchyroll',
  'Discovery+',
  'Disney+',
  'ESPN+',
  'Facebook Watch',
  'Freevee',
  'HBO Max',
  'Hulu',
  'IMDb',
  'Netflix',
  'Pantaya',
  'Paramount+',
  'Peacock',
  'Pluto TV',
  'Roku',
  'Shudder',
  'Sundance Now',
  'WE tv+',
  'YouTube'
]
export const broadcastNetworks = [
  {
    network: '-- BROADCAST NETWORK --',
    disabled: true
  },
  {
    network: 'ABC',
    disabled: false
  },
  {
    network: 'CBS',
    disabled: false
  },
  {
    network: 'CW',
    disabled: false
  },
  {
    network: 'FOX',
    disabled: false
  },
  {
    network: 'NBC',
    disabled: false
  },
  {
    network: 'PBS',
    disabled: false
  },
  {
    network: '-- CABLE NETWORK --',
    disabled: true
  },
  {
    network: 'A&E',
    disabled: false
  },
  {
    network: 'Acorn TV',
    disabled: false
  },
  {
    network: 'Adult Swim',
    disabled: false
  },
  {
    network: 'AMC',
    disabled: false
  },
  {
    network: 'Animal Planet',
    disabled: false
  },
  {
    network: 'BBC America',
    disabled: false
  },
  {
    network: 'BET Networks',
    disabled: false
  },
  {
    network: 'Bravo',
    disabled: false
  },
  {
    network: 'Cartoon Network',
    disabled: false
  },
  {
    network: 'Cinemax',
    disabled: false
  },
  {
    network: 'CMT',
    disabled: false
  },
  {
    network: 'Comedy Central',
    disabled: false
  },
  {
    network: 'Discovery Channel',
    disabled: false
  },
  {
    network: 'Disney Channel',
    disabled: false
  },
  {
    network: 'Disney XD',
    disabled: false
  },
  {
    network: 'DIY Network',
    disabled: false
  },
  {
    network: 'E!',
    disabled: false
  },
  {
    network: 'El Rey Network',
    disabled: false
  },
  {
    network: 'Epix',
    disabled: false
  },
  {
    network: 'ESPN',
    disabled: false
  },
  {
    network: 'Food Network',
    disabled: false
  },
  {
    network: 'Freeform',
    disabled: false
  },
  {
    network: 'Fuse',
    disabled: false
  },
  {
    network: 'FX Networks',
    disabled: false
  },
  {
    network: 'Hallmark Channel',
    disabled: false
  },
  {
    network: 'HBO',
    disabled: false
  },
  {
    network: 'HGTV',
    disabled: false
  },
  {
    network: 'History',
    disabled: false
  },
  {
    network: 'IFC',
    disabled: false
  },
  {
    network: 'Investigation Discovery',
    disabled: false
  },
  {
    network: 'Lifetime',
    disabled: false
  },
  {
    network: 'Logo',
    disabled: false
  },
  {
    network: 'MTV',
    disabled: false
  },
  {
    network: 'National Geographic',
    disabled: false
  },
  {
    network: 'Nickelodeon',
    disabled: false
  },
  {
    network: 'Other',
    disabled: false
  },
  {
    network: 'OWN',
    disabled: false
  },
  {
    network: 'Oxygen',
    disabled: false
  },
  {
    network: 'Paramount Network',
    disabled: false
  },
  {
    network: 'Pivot',
    disabled: false
  },
  {
    network: 'Pop TV',
    disabled: false
  },
  {
    network: 'Reelz Channel',
    disabled: false
  },
  {
    network: 'Showtime',
    disabled: false
  },
  {
    network: 'Smithsonian',
    disabled: false
  },
  {
    network: 'Spectrum',
    disabled: false
  },
  {
    network: 'Starz',
    disabled: false
  },
  {
    network: 'SundanceTV',
    disabled: false
  },
  {
    network: 'SYFY',
    disabled: false
  },
  {
    network: 'TBS',
    disabled: false
  },
  {
    network: 'Telemundo',
    disabled: false
  },
  {
    network: 'TLC',
    disabled: false
  },
  {
    network: 'TNT',
    disabled: false
  },
  {
    network: 'Travel Channel',
    disabled: false
  },
  {
    network: 'TruTV',
    disabled: false
  },
  {
    network: 'TV Land',
    disabled: false
  },
  {
    network: 'Univision',
    disabled: false
  },
  {
    network: 'USA',
    disabled: false
  },
  {
    network: 'VH1',
    disabled: false
  },
  {
    network: 'Viceland',
    disabled: false
  },
  {
    network: 'WE tv',
    disabled: false
  },
  {
    network: 'WWE Network',
    disabled: false
  }
]