import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'react-bootstrap/Dropdown';
import { startLogout } from '../actions/auth';
import { auth } from '../firebase/firebase';

const NavigationMobile = ({ startLogout }) => {
  const user = auth.currentUser;
  return (
    <Dropdown className='nav-mobile'>
      <Dropdown.Toggle className='header--dropdown' id="dropdown-basic">
        <FontAwesomeIcon icon={ faBars } className='header--hamburger-icon' />
      </Dropdown.Toggle>
        {user !== null ? (
          <Dropdown.Menu className='header--nav-mobile-menu'>
            <Link 
              className='nav-link dropdown-item'
              to='/dashboard'
            >Dashboard</Link>
            <Link 
              className='nav-link dropdown-item'
              to='/create-entry'
            >Create an Entry</Link>
            <Link
              className='nav-link dropdown-item'
              to='/submission-guidelines'
            >Submission Guidelines</Link>
            <Link 
              className='nav-link dropdown-item'
              to='/contact-us'
            >Contact Us</Link>
            <Link 
              className='nav-link dropdown-item'
              to='/orders'
            >Orders</Link>
            <Dropdown.Item 
              className='nav-link header--sign-out-dropdown-link'
              onClick={startLogout}
            >Sign Out</Dropdown.Item>
          </Dropdown.Menu>
        ) : (
          <Dropdown.Menu className='header--nav-mobile-menu'>
            <Link 
              className='nav-link dropdown-item'
              to='/login-page'
            >Login</Link>
            <Link
              className='nav-link dropdown-item'
              to='/submission-guidelines'
            >Submission Guidelines</Link>
            <Link 
              className='nav-link dropdown-item'
              to='/contact-us'
            >Contact Us</Link>
          </Dropdown.Menu>
        )}
    </Dropdown>
  )
};

const mapDispatchToProps = (dispatch) => ({
  startLogout: () => dispatch(startLogout())
});

export default connect(undefined, mapDispatchToProps)(NavigationMobile);