import React from 'react';
import Container from 'react-bootstrap/Container';
import Footer from '../components/Footer';
import Header from '../components/Header';

const Layout = ({ children, pageId, pageTitle }) => (
  <>
    <Header pageTitle={pageTitle} />
    <Container fluid='sm' as='main' id={pageId} className='main-content'>{children}</Container>
    <Footer />
  </>
)

export default Layout