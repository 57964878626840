import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import TVAwardsLogo from '../images/hca-awards-logo.png';

const Home = () => (
  <>
    <main className='home--main'>
      <img src={TVAwardsLogo} className='home--logo' alt='Hollywood Creative Alliance Film Awards' />
      <h1 className='home--submissions-text'>SUBMISSIONS ARE NOW OPEN</h1>
      <div className='home--members-button-group'>
        <Link to='/login-page'><Button className='me-3' variant='warning'>Login</Button></Link>
        <Link to='/register-page'><Button variant='warning'>Register</Button></Link>
      </div>
      {/* <div className='home--members-button-group'>
        <a href='https://www.youtube.com/watch?v=Ot-0RuTfw8I' target='_blank' rel='noreferrer'><Button className='me-3' variant='warning'>Broadcast &amp; Cable</Button></a>
        <a href='https://www.youtube.com/watch?v=6yqhAEUbbzo' target='_blank' rel='noreferrer'><Button variant='warning'>Streaming</Button></a>
      </div> */}
    </main>
    <footer className='home--footer'>
      <p className='footer--legal'>© 2023 Hollywood Creative Alliance | All Rights Reserved</p>
    </footer>
  </>
);

export default Home