export const setCategoryFilter = (category = '') => ({
  type: 'SET_CATEGORY_FILTER',
  category
});
export const setEntryFilter = (entry = '') => ({
  type: 'SET_ENTRY_FILTER',
  entry
});
export const setFilmFilter = (film = '') => ({
  type: 'SET_FILM_FILTER',
  film
});
export const setStudioFilter = (studio = '') => ({
  type: 'SET_STUDIO_FILTER',
  studio
});

export const sortByDateRecent = () => ({
  type: 'SORT_BY_RECENT_DATE'
});
export const sortByDateOldest = () => ({
  type: 'SORT_BY_OLDEST_DATE'
});
export const sortByCategoryAZ = () => ({
  type: 'SORT_BY_CATEGORY_AZ'
});
export const sortByCategoryZA = () => ({
  type: 'SORT_BY_CATEGORY_ZA'
});
export const sortByEntryAZ = () => ({
  type: 'SORT_BY_ENTRY_AZ'
});
export const sortByEntryZA = () => ({
  type: 'SORT_BY_ENTRY_ZA'
});
export const sortByUnpaid = () => ({
  type: 'SORT_BY_UNPAID'
});
export const sortByPaid = () => ({
  type: 'SORT_BY_PAID'
});