import React from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { startRegister } from '../actions/auth';
import Layout from '../components/Layout';

export class RegisterPage extends React.Component {
  state = {
    validated: false,
    email: '',
    name: '',
    pass: ''
  };
  handleEmailValueChange = (e) => {
    const email = e.target.value;
    this.setState(() => ({ email }));
  }
  handleNameValueChange = (e) => {
    const name = e.target.value;
    this.setState(() => ({ name }));
  }
  handlePassValueChange = (e) => {
    const pass = e.target.value;
    this.setState(() => ({ pass }));
  }
  handleSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      this.props.startRegister({
        email: this.state.email, 
        password: this.state.pass,
        name: this.state.name
      });
    }
    this.setState(() => ({ validated: true }));
  };
  render() {
    return (
      <Layout pageId='register-page' pageTitle='REGISTER'>
        <Row>
          <Col md={{ span: 6, offset: 3 }} className='main--register-form'>
            <Form className='w-100 needs-validation' noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
              <Row>
                <Form.Group as={Col} md={12} controlId='formName' className='register-form__input'>
                  <Form.Control 
                    type='text' 
                    onChange={this.handleNameValueChange}
                    placeholder='Full Name' 
                    required
                  />
                  <Form.Control.Feedback type='invalid'>
                    Please enter an email address.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6} controlId='formEmail' className='register-form__input'>
                  <Form.Control 
                    type='email' 
                    onChange={this.handleEmailValueChange}
                    placeholder='Enter email' 
                    required
                  />
                  <Form.Control.Feedback type='invalid'>
                    Please enter an email address.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6} controlId='formPass' className='register-form__input'>
                  <Form.Control 
                    type='password' 
                    onChange={this.handlePassValueChange}
                    placeholder='Password' 
                    required
                  />
                  <Form.Control.Feedback type='invalid'>
                    Please enter a password.
                  </Form.Control.Feedback>
                </Form.Group>
                <Col>
                  <Button type='submit' variant='warning'>Register</Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Layout>
    )
  };
};

const mapDispatchToProps = (dispatch) => ({
  startRegister: (email, pass) => dispatch(startRegister(email, pass))
});

export default connect(undefined, mapDispatchToProps)(RegisterPage);