import React from 'react';
import { connect } from 'react-redux';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { startAddNomination } from '../actions/nominations';
import EntryForm from '../components/EntryForm';
import FirstEvent from '../components/timeline/FirstEvent';
import Layout from '../components/Layout';

export class AddEntryPage extends React.Component {
  handleSubmit = (nomination) => {
    this.props.startAddNomination(nomination);
  }
  render() {
    return (
      <Layout pageId='add-entry' pageTitle='CREATE AN ENTRY'>
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <FirstEvent />
          </Col>
          <Col md={{ span: 10, offset: 1 }} className='main--entry-form'>
            <h2 className='mb-2'>Entry Fees</h2>
            <p><strong>$300</strong> per program and film entries.</p>
            <p><strong>$100</strong> per individual in all Creative Arts categories</p>

            <EntryForm
              handleSubmit={this.handleSubmit}
            />
          </Col>
        </Row>
      </Layout>
    )
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    startAddNomination: (nomination) => dispatch(startAddNomination(nomination))
  }
};

export default connect(undefined, mapDispatchToProps)(AddEntryPage);