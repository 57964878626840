import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import AdminNominationsList from '../components/AdminNominationsList';
import Layout from '../components/Layout';
import NominationsList from '../components/NominationsList';
import { auth } from '../firebase/firebase';

const AwardsDashboardPage = () => {
  const user = auth.currentUser;
  let dashboard;
  // if (user.uid === '6TOSUrbrLMNteqU4cF3kJQOcE0d2') { // DEV UID
  if (user.uid === 'QAgh6U0NVfMPG95KQdmdFQswfEW2') { // MAIN UID
    dashboard = <AdminNominationsList />
  }
  //  else if (user.uid === 'RvqGWriApwOuJYKkHVnC1RqJlVq2') {
  //   dashboard = <AdminNominationsList />
  // } 
  else {
    dashboard = <NominationsList />
  }
  return (
    <Layout pageId='dashboard' pageTitle='DASHBOARD'>
      <Row>
        <Col md={{ span: 10, offset: 1 }} className='main--nomination-list'>
          {dashboard}
        </Col>
      </Row>
    </Layout>
  )
};

export default AwardsDashboardPage