import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { child, get, ref } from 'firebase/database';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import db, { auth } from '../firebase/firebase';
import Layout from '../components/Layout';
import CheckoutForm from '../components/CheckoutForm';
import ProductStatue from '../images/product-statues.jpg';

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);

export default function OrdersPage() {
  const [clientSecret, setClientSecret] = useState("");
  const [seriesCount, setSeriesCount] = useState(0);
  const [talentCount, setTalentCount] = useState(0);

  const uid = auth.currentUser.uid;
  const dbRef = ref(db);

  const nominations = [];
  let _isMounted = true;
  let sCount = 0;
  let tCount = 0;
  let allEntriesPaidFor = false;

  useEffect(() => {
    // GET ENTRY COUNTS
    if (_isMounted) {
      get(child(dbRef, `users/${uid}/nomination`)).then((snapshot) => {
        if (snapshot.exists()) {
          snapshot.forEach((childSnapshot) => {
            nominations.push({
              paid: childSnapshot.val().paid,
              inCart: childSnapshot.val().inCart,
              entry: childSnapshot.val().entry,
              seriesOrTalent: childSnapshot.val().seriesOrTalent
            });
          });
          for (let i = 0; i < nominations.length; i++) {
            if (nominations[i].seriesOrTalent === 'series' && nominations[i].paid === false) {
              allEntriesPaidFor = false;
              sCount++;
            } else if (nominations[i].seriesOrTalent === 'talent' && nominations[i].paid === false && nominations[i].inCart) {
              allEntriesPaidFor = false;
              tCount += nominations[i].entry.split(",").length;
            } else if (tCount === 0 && sCount === 0 && nominations[i].paid === true) {
              allEntriesPaidFor = true;
            }
          }

          _isMounted && setSeriesCount(sCount);
          _isMounted && setTalentCount(tCount);

          // Create PaymentIntent as soon as the page loads
          if (!allEntriesPaidFor) {
            fetch("/create-payment-intent", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                items: [
                  {
                    id: 'series-entry', // SERIES ID
                    amount: sCount === undefined ? 0 : sCount
                  }, {
                    id: 'talent-entry',
                    amount: tCount === undefined ? 0 : tCount
                  }],
                email: auth.currentUser.email
              }),
            }).then((res) => res.json()).then((data) => setClientSecret(data.clientSecret));
          }
        } else {
          console.log('NO DATA AVAILABLE');
        }
      }).catch((error) => {
        console.error(error);
      });
    }
  }, []);

  const appearance = {
    theme: 'stripe',
  };

  const options = {
    clientSecret,
    appearance,
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <Layout pageId='orders' pageTitle='ORDERS'>
      <Row>
        <Col md={{ span: 10, offset: 1 }} className='main--orders flex-column'>
          <Row>
            <Col md={5} className='pe-4'>
              <Table className='orders--table table-sm'>
                <thead>
                  <tr>
                    <th colSpan={3}>
                      <h2 className='orders--dollar-amount'>Total: {formatter.format((seriesCount * 300) + (talentCount * 100))}</h2>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr className='align-middle'>
                    <td width='10%'>
                      <img alt='Product Image' className='orders--product-image' src={ProductStatue} />
                    </td>
                    <td>
                      <div className='orders--product-text'>
                        <p className='m-0'>Program and Film Entry</p>
                        <p className='orders--quantity m-0'>Quantity: {seriesCount} x $300.00</p>
                      </div>
                    </td>
                    <td>
                      <h3 className='orders--dollar-amount'>{formatter.format(seriesCount * 300)}</h3>
                    </td>
                  </tr> */}
                  <tr className='align-middle'>
                    <td width='10%'>
                      <img alt='Product Image' className='orders--product-image' src={ProductStatue} />
                    </td>
                    <td>
                      <div className='orders--product-text'>
                        <p className='m-0'>Talent Entry</p>
                        <p className='orders--quantity m-0'>Quantity: {talentCount} x $100.00</p>
                      </div>
                    </td>
                    <td>
                      <h3 className='orders--dollar-amount'>{formatter.format(talentCount * 100)}</h3>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col md={7} className='pt-3 ps-4'>
              {clientSecret ? (
                <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm />
                </Elements>
              ) : (
                <p>You have no entries in your cart.</p>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
};