import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AppRouter, { history } from './routers/AppRouter';
import configureStore from './store/configureStore';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { startSetNominations, startSetAdminNominations } from './actions/nominations';
import { auth } from './firebase/firebase';
import { login, logout } from './actions/auth';

import '@fontsource/raleway/100.css';
import '@fontsource/raleway/300.css';
import '@fontsource/raleway/700.css';
import './styles/styles.scss';

let hasRendered = false;
const store = configureStore();
const renderApp = () => {
  if (!hasRendered) {
    ReactDOM.render(jsx, document.getElementById('app'));
    hasRendered = true;
  }
}

const jsx = (
  <Provider store={store}>
    <AppRouter />
  </Provider>
);

ReactDOM.render(<div className='loading-data-page'><h1>LOADING DATA...</h1></div>, document.getElementById('app'));

onAuthStateChanged(auth, (user) => {
  if (user) {
    store.dispatch(login(user.uid));
    if (user.uid === 'QAgh6U0NVfMPG95KQdmdFQswfEW2') { // MAIN
    // if (user.uid === '6TOSUrbrLMNteqU4cF3kJQOcE0d2') { // TEST
      store.dispatch(startSetAdminNominations());
    }
    store.dispatch(startSetNominations()).then(() => {
      renderApp();

      if (auth.currentUser.emailVerified === false) {
        signOut(auth);
        history.push('/login-page');
      } else if (history.location.pathname === '/payment-complete') {

      }  else {
        history.push('/dashboard');
      }
    });
  } else {
    store.dispatch(logout());
    renderApp();
    if (history.location.pathname === '/register-page' || history.location.pathname === '/login-page') {

    } else {
      history.push('/');
    }
  }
});