import React from 'react';
import { connect } from 'react-redux';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { startEditNomination } from '../actions/nominations';
import EntryForm from '../components/EntryForm';
import Layout from '../components/Layout';

export class EditEntryPage extends React.Component {
  handleSubmit = (nomination) => {
    this.props.startEditNomination(this.props.nomination.id, nomination);
    this.props.history.push('/dashboard');
  };

  render() {
    return (
      <Layout pageId='edit-entry' pageTitle='EDIT AN ENTRY'>
        <Row>
          <Col md={{ span: 10, offset: 1 }} className='main--entry-form-edit'>
            <EntryForm
              className='mb-5'
              entryId={this.props.match.params.id}
              nomination={this.props.nomination}
              handleSubmit={this.handleSubmit}
            />
          </Col>
        </Row>
      </Layout>
    )
  }
};

const mapStateToProps = (state, props) => ({
  nomination: state.nominations.find((nomination) => nomination.id === props.match.params.id)
});

const mapDispatchToProps = (dispatch) => ({
  startEditNomination: (id, nomination) => dispatch(startEditNomination(id, nomination))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditEntryPage);