import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import AddEntryPage from '../pages/AddEntryPage';
import AddEntrySummaryPage from '../pages/AddEntrySummaryPage';
import AdminEntrySummary from '../pages/AdminEntrySummary';
import AwardsDashboardPage from '../pages/AwardsDashboardPage';
import ContactUsPage from '../pages/ContactUsPage';
import EditEntryPage from '../pages/EditEntryPage';
import EntrySuccessful from '../pages/EntrySuccessful';
import Home from '../pages/HomePage';
import LoginPage from '../pages/LoginPage';
import RegisterPage from '../pages/RegisterPage';
import ResetPasswordPage from '../pages/ResetPasswordPage';
import NotFoundPage from '../pages/NotFoundPage';
import OrdersPage from '../pages/OrdersPage';
import PaymentCompletePage from '../pages/PaymentCompletePage';
import SubmissionGuidelinesPage from '../pages/SubmissionGuidelinesPage';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

export const history = createBrowserHistory();

const AppRouter = () => (
  <Router history={history}>
    <Switch>
      <PublicRoute exact={true} path='/' component={Home} />
      <Route path='/register-page' component={RegisterPage} />
      <Route path='/login-page' component={LoginPage} />
      <PrivateRoute path='/dashboard' component={AwardsDashboardPage} />
      <Route path='/submission-guidelines' component={SubmissionGuidelinesPage} />
      <Route path='/contact-us' component={ContactUsPage} />
      <PrivateRoute path='/create-entry' component={AddEntryPage} />
      <PrivateRoute path='/create-entry-summary/:id' component={AddEntrySummaryPage} />
      <PrivateRoute path='/edit-entry/:id' component={EditEntryPage} />
      <PrivateRoute path='/orders' component={OrdersPage} />
      <PrivateRoute path='/entry-successful' component={EntrySuccessful} />
      <PrivateRoute path='/entry-summary/:id' component={AdminEntrySummary} />
      <Route path='/payment-complete' component={PaymentCompletePage} />
      <Route path='/reset-password' component={ResetPasswordPage} />
      <Route component={NotFoundPage} />
    </Switch>
  </Router>
);

export default AppRouter;