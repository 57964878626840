import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import {
  setEntryFilter,
  setCategoryFilter,
  setFilmFilter,
  setStudioFilter,
  sortByDateRecent,
  sortByDateOldest,
  sortByCategoryAZ,
  sortByCategoryZA,
  sortByEntryAZ,
  sortByEntryZA
} from '../actions/filters';

export class NominationListFilters extends React.Component {
  state = {
    searchType: 'entry'
  }
  handleSearchTypeChange = (e) => {
    switch (e.target.value) {
      case 'searchEntry':
        this.setState(() => ({ searchType: 'entry' }));
        break;
      case 'searchCategory':
        this.setState(() => ({ searchType: 'category' }));
        break;
      case 'searchFilm':
        this.setState(() => ({ searchType: 'film' }));
        break;
      case 'searchStudio':
        this.setState(() => ({ searchType: 'studio' }));
        break;
    }
  };
  handleTextChange = (e) => {
    if (this.state.searchType === 'entry') {
      this.props.setEntryFilter(e.target.value);
    } else if (this.state.searchType === 'category') {
      this.props.setCategoryFilter(e.target.value);
    } else if (this.state.searchType === 'film') {
      this.props.setFilmFilter(e.target.value);
    } else if (this.state.searchType === 'studio') {
      this.props.setStudioFilter(e.target.value);
    }
  };
  handleSortChange = (e) => {
    switch (e.target.value) {
      case 'recentDate':
        this.props.sortByDateRecent();
        break;
      case 'oldestDate':
        this.props.sortByDateOldest();
        break;
      case 'categoryAZ':
        this.props.sortByCategoryAZ();
        break;
      case 'categoryZA':
        this.props.sortByCategoryZA();
        break;
      case 'entryAZ':
        this.props.sortByEntryAZ();
        break;
      case 'entryZA':
        this.props.sortByEntryZA();
        break;
    }
  };
  render() {
    return (
      <Row>
        <Col sm={9} className='nomination-list--filters'>
          <Row>
            <InputGroup as={Col} className='nomination-list--search-group'>
              <Form.Control
                placeholder='Search'
                className='nomination-list--search-filter'
                type='text'
                defaultValue={this.props.filters.entry}
                onChange={this.handleTextChange}
              />
              <Form.Select
                className='nomination-list--search-type-filter'
                onChange={this.handleSearchTypeChange}>
                <option value='searchEntry'>Entry</option>
                <option value='searchCategory'>Category</option>
                <option value='searchFilm'>Film or Series Title</option>
                <option value='searchStudio'>Studio or Network</option>
              </Form.Select>
            </InputGroup>
            <InputGroup as={Col}>
              <Form.Label className='nomination-list--sort-filter-label'>Sort by:</Form.Label>
              <Form.Select className='nomination-list--sort-filter' onChange={this.handleSortChange}>
                <option value='recentDate'>Most Recent</option>
                <option value='oldestDate'>Oldest</option>
                <option value='categoryAZ'>Category: A-Z</option>
                <option value='categoryZA'>Category: Z-A</option>
                <option value='entryAZ'>Entry: A-Z</option>
                <option value='entryZA'>Entry: Z-A</option>
              </Form.Select>
            </InputGroup>
          </Row>
        </Col>
        <Col className='nomination-list--new-entry' sm={{ span: 2, offset: 1 }}>
          {this.props.nominations.findIndex(a => !a.paid && a.inCart) !== -1 && <Link to='/orders'><Button variant='warning' className='mx-2'>Pay</Button></Link>}
          <Link to='/create-entry'><Button variant='warning'><FontAwesomeIcon icon={faPlus} className='nav--icon' />Entry</Button></Link>
        </Col>
      </Row>
    )
  }
};

const mapStateToProps = (state) => ({
  filters: state.filters,
  nominations: state.nominations,
});
const mapDispatchToProps = (dispatch) => ({
  setEntryFilter: (text) => dispatch(setEntryFilter(text)),
  setCategoryFilter: (text) => dispatch(setCategoryFilter(text)),
  setFilmFilter: (text) => dispatch(setFilmFilter(text)),
  setStudioFilter: (text) => dispatch(setStudioFilter(text)),
  sortByDateRecent: () => dispatch(sortByDateRecent()),
  sortByDateOldest: () => dispatch(sortByDateOldest()),
  sortByCategoryAZ: () => dispatch(sortByCategoryAZ()),
  sortByCategoryZA: () => dispatch(sortByCategoryZA()),
  sortByEntryAZ: () => dispatch(sortByEntryAZ()),
  sortByEntryZA: () => dispatch(sortByEntryZA())
});

export default connect(mapStateToProps, mapDispatchToProps)(NominationListFilters);