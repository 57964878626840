import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Layout from '../components/Layout';

const PaymentCompletePage = () => (
  <Layout pageId='order-complete' pageTitle='PAYMENT COMPLETE'>
    <Row>
      <Col md={{ span: 10, offset: 1 }} className='main--payment-complete flex-column'>
        <h2>PAYMENT SUCCESSFUL</h2>
        <p>Your payment was successfully received.</p>
      </Col>
    </Row>
  </Layout>
);

export default PaymentCompletePage