import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { startLogin } from '../actions/auth';
import Layout from '../components/Layout';

export class LoginPage extends React.Component {
  state = {
    validated: false,
    email: '',
    pass: ''
  };
  handleEmailValueChange = (e) => {
    const email = e.target.value;
    this.setState(() => ({ email }));
  }
  handlePassValueChange = (e) => {
    const pass = e.target.value;
    this.setState(() => ({ pass }));
  }
  handleSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      this.props.startLogin({
        email: this.state.email, 
        password: this.state.pass
      });
    }
    this.setState(() => ({ validated: true }));
  };
  render() {
    return (
      <Layout pageId='login-page' pageTitle='LOGIN'>
        <Row>
          <Col md={{ span: 6, offset: 3 }} className='main--login-form'>
            <Form className='w-100' noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
              <Form.Group as={Col} controlId='formEmail' className='login-form__input'>
                <Form.Control 
                  type='email' 
                  onChange={this.handleEmailValueChange}
                  placeholder='Enter email' 
                  required 
                  />
                <Form.Control.Feedback type='invalid'>
                  Please enter an email address.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId='formPass' className='login-form__input'>
                <Form.Control 
                  type='password' 
                  onChange={this.handlePassValueChange}
                  placeholder='Password' 
                  required 
                />
                <Form.Control.Feedback type='invalid'>
                  Please enter a password.
                </Form.Control.Feedback>
              </Form.Group>
              <Col>
                <Button className='me-2' type='submit' variant='warning'>Login</Button>
                <Link to='/reset-password'><Button type='button' variant='warning'>Reset Password</Button></Link>
              </Col>
            </Form>
          </Col>
        </Row>
      </Layout>
    )
  };
};

const mapDispatchToProps = (dispatch) => ({
  startLogin: (email, pass) => dispatch(startLogin(email, pass))
});

export default connect(undefined, mapDispatchToProps)(LoginPage)