const filtersReducerDefaultState = {
  createdOn: undefined,
  category: '',
  entry: '',
  film: '',
  studio: '',
  sortBy: 'dateRecent'
};
export default (state = filtersReducerDefaultState, action) => {
  switch (action.type) {
    case 'SET_CATEGORY_FILTER':
      return {
        ...state,
        category: action.category
      }
    case 'SET_ENTRY_FILTER':
      return {
        ...state,
        entry: action.entry
      }
    case 'SET_FILM_FILTER':
      return {
        ...state,
        film: action.film
      }
    case 'SET_STUDIO_FILTER':
      return {
        ...state,
        studio: action.studio
      }
    case 'SORT_BY_RECENT_DATE':
      return {
        ...state,
        sortBy: 'dateRecent'
      }
    case 'SORT_BY_OLDEST_DATE':
      return {
        ...state,
        sortBy: 'dateOldest'
      }
    case 'SORT_BY_CATEGORY_AZ':
      return {
        ...state,
        sortBy: 'categoryAZ'
      }
    case 'SORT_BY_CATEGORY_ZA':
      return {
        ...state,
        sortBy: 'categoryZA'
      }
    case 'SORT_BY_ENTRY_AZ':
      return {
        ...state,
        sortBy: 'entryAZ'
      }
    case 'SORT_BY_ENTRY_ZA':
      return {
        ...state,
        sortBy: 'entryZA'
      }
    case 'SORT_BY_UNPAID':
      return {
        ...state,
        sortBy: 'paidFalse'
      }
    case 'SORT_BY_PAID':
      return {
        ...state,
        sortBy: 'paidTrue'
      }
    default:
      return state;
  }
}