export default (nominations, { createdOn, category, entry, film, studio, sortBy, paid }) => {
  return nominations.filter((nomination) => {
    const createdOnMatch = typeof createdOn !== 'number' || nomination.createdOn >= createdOn;
    const categoryMatch = nomination.category.toLowerCase().includes(category.toLowerCase());
    const entryMatch = nomination.entry.toLowerCase().includes(entry.toLowerCase());
    const filmMatch = nomination.title.toLowerCase().includes(film.toLowerCase());
    const studioMatch = nomination.network.toLowerCase().includes(studio.toLowerCase());
    const paidMatch = typeof paid !== 'boolean';

    return createdOnMatch && categoryMatch && entryMatch && filmMatch && studioMatch && paidMatch;
  }).sort((a, b) => {
    switch (sortBy) {
      case 'dateRecent':
        return a.createdOn < b.createdOn ? 1 : -1;
      case 'dateOldest':
        return a.createdOn < b.createdOn ? -1 : 1;
      case 'categoryAZ':
        return a.category > b.category ? 1 : -1;
      case 'categoryZA':
        return a.category > b.category ? -1 : 1;
      case 'entryAZ':
        return a.entry > b.entry ? 1 : -1;
      case 'entryZA':
        return a.entry > b.entry ? -1 : 1;
      case 'paidFalse':
        return a.paid > b.paid ? 1 : -1;
      case 'paidTrue':
        return a.paid > b.paid ? -1 : 1;
    }
  });
};