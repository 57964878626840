import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward } from '@fortawesome/free-solid-svg-icons';
import { getDownloadURL, ref as storageRef } from 'firebase/storage';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { startMarkAsPaid } from '../actions/nominations';
import Layout from '../components/Layout';
import { storage } from '../firebase/firebase';

import '../../node_modules/react-lazy-load-image-component/src/effects/blur.css';

export class AdminEntrySummary extends React.Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
    this.state = {
      imageURL: '',
      videoURL: ''
    }
  }

  componentDidMount() {
    this._isMounted = true;

    if (this._isMounted) {
      if (this.props.location.state.imageFilename !== '') {
        getDownloadURL(storageRef(storage, this.props.location.state.imageFilename)).then((url) => {
          this._isMounted && this.setState(() => ({ imageURL: url }));
        });
      }
      if (this.props.location.state.videoFilename !== '' ) {
        getDownloadURL(storageRef(storage, this.props.location.state.videoFilename)).then((url) => {
          this._isMounted && this.setState(() => ({ videoURL: url }));
        });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleClick = () => {
    this.props.startMarkAsPaid(this.props.entries.entryId, this.props.entries);
    this.props.history.push('/dashboard');
  }

  render() {
    const entry = this.props.location.state;
    return (
      <Layout pageId='admin-entry' pageTitle='ADMIN: ENTRY'>
        <Row>
          <Col md={{ span: 8, offset: 2 }} className='main--summary flex-column'>
            {
              entry.seriesOrTalent === 'talent' && 
              (
                <>
                <Table className='table-striped'>
                  <tbody>
                    <tr>
                      <th scope='row' width='35%'>Category: </th>
                      <td>{entry.category}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Name: </th>
                      <td>{entry.entry}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Series or Movie Title: </th>
                      <td>{entry.title}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Episode Title: </th>
                      <td>{'“' + entry.episodeName + '”'}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Network: </th>
                      <td>{entry.network}</td>
                    </tr>
                    {this.state.imageURL !== '' && 
                      <tr>
                        <th scope='row'>Image: </th>
                        <td>
                          <LazyLoadImage 
                            alt='Talent Image' 
                            className='w-100' 
                            effect='blur'
                            src={this.state.imageURL} 
                          />
                        </td>
                      </tr>
                    }
                    {entry.screenerLink !== '' && 
                      <tr>
                        <th scope='row'>Screener Link: </th>
                        <td>{entry.screenerLink}</td>
                      </tr>
                    }
                    {entry.screenerLinkPass !== '' && 
                      <tr>
                        <th scope='row'>Password for Screener Link: </th>
                        <td>{entry.screenerLinkPass}</td>
                      </tr>
                    }
                    {this.state.videoURL !== '' && 
                      <tr>
                        <th scope='row'>Screener File: </th>
                        <td><a href={this.state.videoURL}>Download</a></td>
                      </tr>
                    }
                    <tr>
                      <th scope='row'>Paid Status: </th>
                      <td>{entry.paid === false ? <Button variant='warning' onClick={this.handleClick}>Mark as Paid</Button> : <h3><Badge bg='success' className='mx-auto'>Paid</Badge></h3>}</td>
                    </tr>
                  </tbody>
                </Table>
                <Col>
                  <Link to='/dashboard'>
                    <Button variant='warning me-2'><FontAwesomeIcon icon={ faBackward } className='nav--icon' />Go Back</Button>
                  </Link>
                </Col>
                </>
              )
            }
            {
              entry.seriesOrTalent === 'series' && 
              (
                <>
                <Table className='table'>
                  <tbody>
                    <tr>
                      <th scope='row' width='36%'>Category: </th>
                      <td>{entry.category}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Name: </th>
                      <td>{entry.entry}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Number of Episodes: </th>
                      <td>{entry.episodeCount}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Plot Summary: </th>
                      <td>{entry.plotSummary}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Network: </th>
                      <td>{entry.network}</td>
                    </tr>
                    {this.state.imageURL !== '' && 
                      <tr>
                        <th scope='row'>Headshot: </th>
                        <td>
                          <LazyLoadImage 
                            alt='Talent Image' 
                            className='w-100' 
                            effect='blur'
                            src={this.state.imageURL} 
                          />
                        </td>
                      </tr>
                    }
                    {entry.screenerLink !== '' && 
                      <tr>
                        <th scope='row'>Screener Link: </th>
                        <td>{entry.screenerLink}</td>
                      </tr>
                    }
                    {entry.screenerLinkPass !== '' && 
                      <tr>
                        <th scope='row'>Password for Screener Link: </th>
                        <td>{entry.screenerLinkPass}</td>
                      </tr>
                    }
                    {this.state.videoURL !== '' && 
                      <tr>
                        <th scope='row'>Screener File: </th>
                        <td><a href={this.state.videoURL}>Download</a></td>
                      </tr>
                    }
                    <tr>
                      <th scope='row'>Paid Status: </th>
                      <td>{entry.paid === false ? <Button variant='warning' onClick={this.handleClick}>Mark as Paid</Button> : <h3><Badge bg='success' className='mx-auto'>Paid</Badge></h3>}</td>
                    </tr>
                  </tbody>
                </Table>
                <Col>
                  <Link to='/dashboard'>
                    <Button variant='warning me-2'><FontAwesomeIcon icon={ faBackward } className='nav--icon' />Go Back</Button>
                  </Link>
                </Col>
                </>
              )
            }
          </Col>
        </Row>
      </Layout>
    )
  }
}

const mapStateToProps = (state, props) => ({
  entries: state.entries.find((entry) => entry.entryId === props.match.params.id)
});

const mapDispatchToProps = (dispatch) => ({
  startMarkAsPaid: (id, nomination) => dispatch(startMarkAsPaid(id, nomination))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminEntrySummary);