const nominationsReducerDefaultState = [];
export default (state = nominationsReducerDefaultState, action) => {
  switch (action.type) {
    case 'ADD_TV_NOMINATION':
      return [
        ...state,
        action.nomination
      ];
    case 'REMOVE_TV_NOMINATION':
      return state.filter(({ id }) => id !== action.id);
    case 'EDIT_TV_NOMINATION':
      return state.map((nomination) => {
        if (nomination.id === action.id) {
          return {
            ...nomination,
            ...action.updates
          }
        } else {
          return nomination;
        }
      });
    case 'SET_TV_NOMINATIONS':
      return action.nominations;
    case 'MARK_AS_PAID':
      return state.map((nomination) => {
        if (nomination.id === action.id) {
          return {
            ...nomination,
            ...action.updates
          }
        } else {
          return nomination;
        }
      });
    default:
      return state;
  }
};