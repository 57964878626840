import React from 'react';
import Badge from 'react-bootstrap/Badge';
import { Link } from 'react-router-dom';

export const AdminNominationListItem = ({ 
  uid,
  entryId,
  category, 
  createdOn, 
  entry, 
  episodeCount, 
  episodeName, 
  imageFilename, 
  network, 
  paid, 
  plotSummary, 
  screenerLink, 
  screenerLinkPass, 
  seriesOrTalent, 
  title, 
  videoFilename
}) => {
  return (
    <tr>
      <td width='15%' className='align-middle py-4 ps-4'>{paid === false ? <Badge bg='info' className='mx-auto'>In Cart</Badge> : <Badge bg='success' className='mx-auto'>Paid</Badge>}</td>
      <td width='17%' className='align-middle py-4'><strong>{category}</strong></td>
      <td width='17%' className='align-middle py-4'>
        <Link 
          className='admin--link'
          to={{
            pathname: `/entry-summary/${entryId}`,
            state: {
              uid,
              entryId,
              category, 
              createdOn, 
              entry, 
              episodeCount, 
              episodeName, 
              imageFilename, 
              network, 
              paid, 
              plotSummary, 
              screenerLink, 
              screenerLinkPass, 
              seriesOrTalent, 
              title, 
              videoFilename
            }
          }}
        >{entry}</Link>
      </td>
      <td width='17%' className='align-middle py-4'><strong>{title}</strong></td>
      <td width='17%' className='align-middle py-4 pe-4'><strong>{episodeName}</strong></td>
    </tr>
  )
};

export default AdminNominationListItem;