import React from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Layout from '../components/Layout';

class ContactUsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      subject:'',
      message: ''
    }
  }

  onNameChange(e) {
    this.setState({name: e.target.value});
  }

  onEmailChange(e) {
    this.setState({email: e.target.value});
  }

  onSubjectChange(e) {
    this.setState({subject: e.target.value});
  }

  onMsgChange(e) {
    this.setState({message: e.target.value});
  }

  handleSubmit(e) {
    e.preventDefault();

    axios({
      method: 'POST', 
      url: '/send', 
      data: this.state
    }).then((response)=>{
      if (response.data.status === 'success') {
        alert('Message Sent.'); 
        this.resetForm();
       } else if(response.data.status === 'fail') {
        alert('Message failed to send.');
      }
    });
  }

  resetForm() {
    this.setState({ name: '', email: '',subject:'', message: '' })
  }

  render() {
    return (
      <Layout pageId='contact-us' pageTitle='CONTACT US'>
        <Row>
          <Col md={{ span: 6, offset: 3 }} className='main--contact'>
            <div className='section-title'>
              <h2 className='title'>Contact Us</h2>
              <p>Let us know what you think! In order to provide better service, please do not hesitate to give us your feedback. Thank you.</p>
              <hr/>
              <Form id='contact-form' onSubmit={this.handleSubmit.bind(this)} method='POST'>
                <Form.Group>
                  <Row>
                    <Col md={6}>
                      <Form.Control
                        className='mb-3'
                        id='name'
                        onChange={this.onNameChange.bind(this)}
                        placeholder='Name'
                        required
                        type='text' 
                        value={this.state.name} 
                      />
                    </Col>
                    <Col md={6}>
                      <Form.Control
                        aria-describedby='emailHelp'
                        className='mb-3'
                        id='email'
                        onChange={this.onEmailChange.bind(this)}
                        placeholder='Email'
                        required 
                        type='email'
                        value={this.state.email} 
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    id='subject'
                    className='mb-3'
                    onChange={this.onSubjectChange.bind(this)}
                    placeholder='Subject'
                    required
                    type='text'
                    value={this.state.subject}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    as='textarea'
                    className='mb-3'
                    id='message' 
                    onChange= {this.onMsgChange.bind(this)}
                    placeholder='Message'
                    required
                    rows={10}
                    value={this.state.message}
                  />
                </Form.Group>
                <Button type='submit' variant='warning'>Submit</Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Layout>
    )
  }
};

export default ContactUsPage