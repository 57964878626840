import React from 'react';

const SecondEvent = () => (
  <div className='line-box'>
    <div className='tl-line done'>
        <div className='dot-title'>
          <h4>Create Entry</h4>
        </div>
        <div className='dot-full'></div>
    </div>
    <div className='tl-line'>
        <div className='dot-title'>
          <h4>Summary</h4>
        </div>
        <div className='dot-full'></div>
    </div>
    <div className='tl-line'>
        <div className='dot-title'>
          <h4>Submitted</h4>
        </div>
        <div className='dot-stroke'></div>
    </div>
  </div>
);

export default SecondEvent;