import React from 'react';
import { connect } from 'react-redux';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import NominationListFilters from './NominationListFilters';
import NominationListItem from './NominationListItem';
import { auth } from '../firebase/firebase';
import selectNominations from '../selectors/nominations';

export const NominationsList = (props) => {
  const user = auth.currentUser;
  console.log(props.nomination)
  return (
    <div>
      <h2>Welcome {user !== null && user.displayName}</h2>
      <p className='mb-5'>Below you will find a listing of all your entries. Click on one of the options to perform a specific task. If you have any questions, please contact the site administrator.</p>
      <NominationListFilters />
      <Row className='mt-5'>
        <Col className='nomination--list' md={12}>
          <Table borderless className='m-0'>
            <thead>
              <tr>
                <th className='align-middle' width='5%'></th>
                <th className='align-middle' width='10%'>Status</th>
                <th className='align-middle' width='15%'>Category</th>
                <th className='align-middle' width='14%'>Entry</th>
                <th className='align-middle' width='14%'>Series or Film</th>
                <th className='align-middle' colSpan={2} width='32%'>Studio / Network</th>
              </tr>
            </thead>
          </Table>
        </Col>
        {props.nomination.length === 0 ? (
          <p>No nominations added yet.</p>
        ) : (
          props.nomination.map((nominee) => {
            return <NominationListItem key={nominee.id} {...nominee} />
          })
        )}
      </Row>
    </div>
  )
};

const mapStateToProps = (state) => {
  return {
    nomination: selectNominations(state.nominations, state.filters)
  };
 };

export default connect(mapStateToProps)(NominationsList);