import React from 'react';
import { connect } from 'react-redux';
import Table from 'react-bootstrap/Table';
import AdminNominationListFilters from './AdminNominationListFilters';
import AdminNominationListItem from './AdminNominationListItem';
import { auth } from '../firebase/firebase';
import selectNominations from '../selectors/nominations';

const AdminNominationsList = (props) => {
  const user = auth.currentUser;
  return (
    <div>
      <h2 className='mb-3'>Welcome {user !== null && user.displayName}</h2>
      <AdminNominationListFilters />
      <Table className='table-striped mt-5'>
        <thead>
          <tr>
            <th width='15%' className='align-middle ps-4'>Paid</th>
            <th width='17%' className='align-middle'>Category</th>
            <th width='17%' className='align-middle'>Entry</th>
            <th width='17%' className='align-middle'>Series/Title</th>
            <th width='17%' className='align-middle pe-4'>Episode Name</th>
          </tr>
        </thead>
        <tbody>
          {props.entries.map((entry) => {
            return <AdminNominationListItem key={entry.entryId} { ...entry } />
          })}
        </tbody>
      </Table>
    </div>
  )
};

const mapStateToProps = (state) => {
  return {
    entries: selectNominations(state.entries, state.filters)
  };
 };

 export default connect(mapStateToProps)(AdminNominationsList);