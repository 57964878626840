import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { startRemoveNomination, startEditNomination } from '../actions/nominations';

export const NominationListItem = ({ inCart, category, entry, title, network, id, imageFilename, paid, startRemoveNomination, startEditNomination, videoFilename }) => {
  const [show, setShow] = useState(false);
  const [select, setSelect] = useState(inCart);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleRemove = () => {
    startRemoveNomination({ id, imageFilename, videoFilename });
  }
  const handleSelect = () => {
    startEditNomination(id, { inCart: !select });
    setSelect(!select);
  }
  return (
    <Col md={12} className='nomination--list-item'>
      <Table borderless className='m-0'>
        <tbody>
          <tr>
            <td className='align-middle' width='5%'>
              <Form.Check type="checkbox"
                disabled={paid}
                defaultChecked={select}
                value={select} onChange={handleSelect} />
            </td>
            <td className='align-middle' width='10%'>{paid === false ? <Badge bg='info' className='mx-auto'>In Cart</Badge> : <Badge bg='success' className='mx-auto'>Paid</Badge>}</td>
            <td className='align-middle' width='15%'><strong>{category}</strong></td>
            <td className='align-middle' width='14%'>{entry}</td>
            <td className='align-middle' width='14%'>{title}</td>
            <td className='align-middle' width='14%'>{network}</td>
            <td className='align-middle' width='18%'>
              <Link to={`/edit-entry/${id}`}><Button className='nomination-list-item--edit-button' variant='warning'>Edit</Button></Link>
              <Button className='nominiation-list-item--modal-show-button' variant='warning' onClick={handleShow}>Remove</Button>
            </td>
          </tr>
        </tbody>
      </Table>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>Are you sure you want to delete this entry?</Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose}>
            Close
          </Button>
          <Button className='nomination-list-item--remove-button' variant="warning" onClick={handleRemove}>Remove</Button>
        </Modal.Footer>
      </Modal>
    </Col>
  )
};

const mapDispatchToProps = (dispatch, props) => ({
  startRemoveNomination: (data) => dispatch(startRemoveNomination(data)),
  startEditNomination: (id, nomination) => dispatch(startEditNomination(id, nomination))
});

export default connect(undefined, mapDispatchToProps)(NominationListItem);