import React from 'react';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Layout from '../components/Layout';

import ThirdEvent from '../components/timeline/ThirdEvent';

export class EntrySuccessful extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    // window.location.replace('https://hca-film-awards-061a2a20bd4f.herokuapp.com/'); // DEV
    window.location.replace('https://hcacreativeartssubmissions.hollywoodcreative.org/'); // MAIN
  }
  
  render() {
    return (
      <>
        <Layout pageId='summary' pageTitle='ENTRY SUMMARY'>
          <Row>
            <Col md={{ span: 10, offset: 1 }}>
              <ThirdEvent />
            </Col>
            <Col md={{ span: 10, offset: 1 }}>
              <h2 className='mb-2 text-center'>Your Entry Has Been Successfully Submitted</h2>
            </Col>
            <Col md={{ span: 10, offset: 1 }}>
              <div className='entry-successful--button-group'>
                <Link to='/create-entry'><Button className='me-3' variant='warning'>Create Another Entry</Button></Link>
                <Button variant='warning' onClick={this.handleSubmit}>Go to Dashboard</Button>
              </div>
            </Col>
          </Row>
        </Layout>
      </>
    )
  }
};

export default EntrySuccessful