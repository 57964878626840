import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import Dropdown from 'react-bootstrap/Dropdown';

import { startLogout } from '../actions/auth';
import NavigationDesktop from './NavigationDesktop';
import NavigationMobile from './NavigationMobile';
import { auth } from '../firebase/firebase';
import TVAwardsLogo from '../images/hca-awards-logo.png';

export const Header = ({ startLogout, pageTitle }) => {
  const user = auth.currentUser;
  return (
    <header id='header' className='header flex-column'>
      <div className='header--top-bar'>
        <Link to='/'><img src={TVAwardsLogo} className='header--logo' alt='Hollywood Creative Alliance Film Awards' /></Link>

        <div className='d-flex flex-row'>
          {user !== null && (
            <Dropdown className='header--dropdown-profile'>
              <Dropdown.Toggle className='header--dropdown' id='dropdown-basic'>
                <FontAwesomeIcon icon={ faUser } className='header--user-icon' /> <span className='me-2'>{user.displayName}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu variant='dark'>
                <Dropdown.Item className='header--dropdown-logout' onClick={startLogout}>Sign Out</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
          <NavigationMobile />
        </div>
      </div>
      <h1 className='header--title'>{pageTitle}</h1>
      <NavigationDesktop />
    </header>
  )
};

const mapDispatchToProps = (dispatch) => ({
  startLogout: () => dispatch(startLogout())
});

export default connect(undefined, mapDispatchToProps)(Header);