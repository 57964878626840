import React from 'react';
import { NavLink } from 'react-router-dom';
import { auth } from '../firebase/firebase';

const NavigationDesktop = () => {
  const user = auth.currentUser;
  return (
    <nav className='nav nav-desktop flex-row'>
      {user !== null ? (
        <>
          <NavLink to='/dashboard'
            className='nav-link'
          >Dashboard</NavLink>
          <NavLink 
            to='/create-entry'
            className='nav-link'
          >Create an Entry</NavLink>
          <NavLink
            to='/submission-guidelines'
            className='nav-link'
          >Submission Guidelines</NavLink>
          <NavLink 
            to='/contact-us'
            className='nav-link'
          >Contact Us</NavLink>
          <NavLink 
            to='/orders'
            className='nav-link'
          >Orders</NavLink>
        </>
      ) : (
        <>
          <NavLink 
            to='/login-page'
            className='nav-link'
          >Login</NavLink>
          <NavLink
            to='/submission-guidelines'
            className='nav-link'
          >Submission Guidelines</NavLink>
          <NavLink 
            to='/contact-us'
            className='nav-link'
          >Contact Us</NavLink>
        </>
      )}
    </nav>
  )
};

export default NavigationDesktop