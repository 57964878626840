import React from 'react';
import { Link } from 'react-router-dom';
import { onValue, ref } from 'firebase/database';
import { getDownloadURL, ref as storageRef } from 'firebase/storage';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Layout from '../components/Layout';
import db, { auth, storage } from '../firebase/firebase';

import SecondEvent from '../components/timeline/SecondEvent';
import '../../node_modules/react-lazy-load-image-component/src/effects/blur.css';

export class AddEntrySummaryPage extends React.Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
    this.state = {
      seriesOrTalent: '',
      category: '',
      entry: '',
      title: '',
      episodeCount: 0,
      episodeName: '',
      plotSummary: '',
      network: '',
      imageURL: '',
      screenerLink: '',
      screenerLinkPass: '',
      videoURL: ''
    }
  }

  componentDidMount() {
    this._isMounted = true;
    const uid = auth.currentUser.uid;

    if (this._isMounted) {
      onValue(ref(db, `users/${uid}/nomination/${this.props.match.params.id}`), (snapshot) => {
        if (snapshot.exists()) {
          const getData = snapshot.val();
          this._isMounted && this.setState(() => ({ seriesOrTalent: getData.seriesOrTalent }));
          this._isMounted && this.setState(() => ({ category: getData.category }));
          this._isMounted && this.setState(() => ({ entry: getData.entry }));
          this._isMounted && this.setState(() => ({ title: getData.title }));
          this._isMounted && this.setState(() => ({ episodeCount: getData.episodeCount }));
          this._isMounted && this.setState(() => ({ episodeName: getData.episodeName }));
          this._isMounted && this.setState(() => ({ plotSummary: getData.plotSummary }));
          this._isMounted && this.setState(() => ({ network: getData.network }));
          this._isMounted && this.setState(() => ({ screenerLink: getData.screenerLink }));
          this._isMounted && this.setState(() => ({ screenerLinkPass: getData.screenerLinkPass }));

          if (getData.imageFilename !== '') {
            getDownloadURL(storageRef(storage, getData.imageFilename)).then((url) => {
              this._isMounted && this.setState(() => ({ imageURL: url }));
            });
          }
          if (getData.videoFilename !== '') {
            getDownloadURL(storageRef(storage, getData.videoFilename)).then((url) => {
              this._isMounted && this.setState(() => ({ videoURL: url }));
            });
          }
        } else {
          console.log('NO DATA FOUND');
        }
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <Layout pageId='summary' pageTitle='ENTRY SUMMARY'>
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <SecondEvent />
          </Col>
          <Col md={{ span: 10, offset: 1 }} className='main--summary flex-column'>
            {
              this.state.seriesOrTalent === 'talent' && 
              (
                <table className='table'>
                  <thead>
                    <tr>
                      <th className='text-center' colSpan={2}>
                        <h2>Your entry has been added!</h2><br />
                        <p>If your information is not correct below, please use the edit button down below. Otherwise, hit &ldquo;Submit&rdquo;!</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope='row' width='36%'>Category: </th>
                      <td>{this.state.category}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Name: </th>
                      <td>{this.state.entry}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Film or Series Title: </th>
                      <td>{this.state.title}</td>
                    </tr>
                    {/* <tr>
                      <th scope='row'>Episode Title: </th>
                      <td>{'“' + this.state.episodeName + '”'}</td>
                    </tr> */}
                    <tr>
                      <th scope='row'>Select a Studio or Network: </th>
                      <td>{this.state.network}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Image: </th>
                      <td>{this.state.imageURL !== '' && 
                        <LazyLoadImage 
                          alt='Talent Image' 
                          className='w-100' 
                          effect='blur'
                          src={this.state.imageURL} 
                        />}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Screener Link: </th>
                      <td>{this.state.screenerLink}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Password for Screener Link: </th>
                      <td>{this.state.screenerLinkPass}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Screener File: </th>
                      <td>{this.state.videoURL !== '' && <video controls src={this.state.videoURL} width="640" />}</td>
                    </tr>
                    <tr>
                      <td colSpan={4}>
                        <Link to={`/edit-entry/${this.props.match.params.id}`}>
                          <Button variant='warning me-2'>Edit</Button>
                        </Link>
                        <Link to='/entry-successful'>
                          <Button variant='warning me-2'>Submit</Button>
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )
            }
            {
              this.state.seriesOrTalent === 'series' && 
              (
                <table className='table'>
                  <thead>
                    <tr>
                      <th colSpan={2}>
                        <h2 className='text-center'>Your entry has been added!</h2><br />
                        <p>If your information is not correct below, please use the edit button down below. Otherwise, hit &ldquo;Submit&rdquo;!</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope='row' width='36%'>Category: </th>
                      <td>{this.state.category}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Name: </th>
                      <td>{this.state.entry}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Number of Episodes: </th>
                      <td>{this.state.episodeCount}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Plot Summary: </th>
                      <td>{this.state.plotSummary}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Network: </th>
                      <td>{this.state.network}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Headshot: </th>
                      <td>{this.state.imageURL !== '' && 
                        <LazyLoadImage 
                          alt='Talent Image' 
                          className='w-100' 
                          effect='blur'
                          src={this.state.imageURL} />}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Screener Link: </th>
                      <td>{this.state.screenerLink}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Password for Screener Link: </th>
                      <td>{this.state.screenerLinkPass}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Screener File: </th>
                      <td>
                      {this.state.videoURL !== '' && <video controls src={this.state.videoURL} width="640" />}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4}>
                        <Link to={`/edit-entry/${this.props.match.params.id}`}>
                          <Button variant='warning me-2'>Edit</Button>
                        </Link>
                        <Link to='/entry-successful'>
                          <Button variant='warning me-2'>Submit</Button>
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )
            }
          </Col>
        </Row>
      </Layout>
    )
  }
}

export default AddEntrySummaryPage