import React from 'react';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Layout from '../components/Layout';

const SubmissionGuidelinesPage = () => (
  <Layout pageId='submission-guidelines' pageTitle='SUBMISSION GUIDELINES'>
    <Row>
      <Col md={{ span: 10, offset: 1 }} className='main--guidelines flex-column'>
        <h3 className='mb-3'>Submission Guidelines:</h3>
        {/* <p><strong>December 31, 2023</strong></p> */}
        <p>HCA Film Creative Arts Submissions are now open.</p>
        <p>Any film released theatrically between January 1, 2023 and December 31, 2023 is eligible.</p>

        <p>Each winning entry will receive a single statuette per individual submitted. Example: If three individuals are submitted for the Best Hair & Makeup category and win that category, all three individuals will receive a statuette. </p>

        <p>*Additional statuette can be ordered by the studio, network, or winner's request for a flat fee of $250 per statue.</p>

        <p>Order Form: <a className='guidelines--link' href='https://buy.stripe.com/7sI02b21jg3jbBu28i'>https://buy.stripe.com/7sI02b21jg3jbBu28i</a></p>

        <h3 className='mt-5 mb-3'>Entry Fees:</h3>

        <p>$100 per individual in all Creative Arts categories</p>

        <strong>All entry fees must be paid by November 10, 2023.</strong>
        
        <h3 className='mt-5 mb-3'>Creative Arts Awards for Film Categories and Information:</h3>
        <ul className='list-unstyled'>
          <li>Best Casting</li>
          <li>Best Cinematography</li>
          <li>Best Costume Design</li>
          <li>Best Editing</li>
          <li>Best Hair & Makeup</li>
          <li>Best Original Song</li>
          <li>Best Production Design</li>
          <li>Best Promotional Campaign </li>
          <li>Best Score</li>
          <li>Best Sound</li>
          <li>Best Stunts</li>
          <li>Best Visual Effects</li>
        </ul>

        <p>There will be five nominees in each category. Nominees will be selected by the HCA Creative Arts Nominating Committee. All Film Branch members will vote for the winners.</p>
        
        <h3 className='mt-5 mb-3'>HCA Creative Arts Awards Timeline:</h3>
        <ul className='list-unstyled'>
          <li><strong>Wednesday, September 27, 2023</strong> - HCA Creative Arts Awards for Film Submissions Open</li>
          <li><strong>Wednesday, October 18, 2023</strong> - HCA Creative Arts Awards for Film Nominating Committee Applications Open</li>
          <li><strong>Wednesday, October 25, 2023</strong> - HCA Creative Arts Awards for Film Nomination Committee Applications Close</li>
          <li><strong>Wednesday, November 8, 2023</strong> - HCA Creative Arts Awards for Film Submissions Close</li>
          <li><strong>Monday, November 27, 2023</strong> - HCA Creative Arts Nominating Committee Voting Begins at 8am PT</li>
          <li><strong>Monday, December 4, 2023</strong> - HCA Creative Arts Nominating Committee Voting Ends at 8:59pm PT</li>
          <li><strong>Thursday, December 7, 2023</strong> - HCA Creative Arts & Film Nomination Announcement at 8:30am PT</li>
          <li><strong>Monday, February 26, 2024</strong> - HCA Creative Awards Awards for Television and Film at City Market Social House</li>
        </ul>

        {/* <h3 className='mt-5 mb-3'>2023 HCA TV Awards Broadcast/Cable Ceremony Categories:</h3>
        <ul className='list-unstyled'>
          <li>Best Broadcast Network or Cable Animated Series or Television Movie</li>
          <li>Best Broadcast Network Reality Show or Competition Series</li>
          <li>Best Cable Reality Show or Competition Series</li>
          <li>Best Broadcast Network or Cable Comedy Special</li>
          <li>Best Broadcast Network Series, Drama</li>
          <li>Best Cable Series, Drama</li>
          <li>Best Actor in a Broadcast Network or Cable Series, Drama</li>
          <li>Best Actress in a Broadcast Network or Cable Series, Drama</li>
          <li>Best Supporting Actor in a Broadcast Network or Cable Series, Drama</li>
          <li>Best Supporting Actress in a Broadcast Network or Cable Series, Drama</li>
          <li>Best Writing in a Broadcast Network or Cable Series, Drama</li>
          <li>Best Directing in a Broadcast Network or Cable Series, Drama</li>
          <li>Best Broadcast Network Series, Comedy</li>
          <li>Best Cable Series, Comedy</li>
          <li>Best Actor in a Broadcast Network or Cable Series, Comedy</li>
          <li>Best Actress in a Broadcast Network or Cable Series, Comedy</li>
          <li>Best Supporting Actor in a Broadcast Network or Cable Series, Comedy</li>
          <li>Best Supporting Actress in a Broadcast Network or Cable Series, Comedy</li>
          <li>Best Writing in a Broadcast Network or Cables Series, Comedy</li>
          <li>Best Directing in a Broadcast Network or Cable Series, Comedy</li>
          <li>Best Broadcast Network or Cable Limited or Anthology Series</li>
          <li>Best Broadcast Network or Cable Live-Action Television Movie</li>
          <li>Best Actor in a Broadcast Network or Cable Limited or Anthology Series or Movie</li>
          <li>Best Actress in a Broadcast Network or Cable Limited or Anthology Series or Movie</li>
          <li>Best Supporting Actor in a Broadcast Network or Cable Limited or Anthology Series or Movie</li>
          <li>Best Supporting Actress in a Broadcast Network or Cable Limited or Anthology Series or Movie</li>
          <li>Best Writing in a Broadcast Network or Cable Limited or Anthology Series or Movie</li>
          <li>Best Directing in a Broadcast Network or Cable Limited or Anthology Series or Movie</li>
        </ul>
        
        <h3 className='mt-5 mb-3'>2023 HCA TV Awards Streaming Ceremony Categories:</h3>
        <ul className='list-unstyled'>
          <li>Best Streaming Animated Series or TV Movie</li>
          <li>Best Streaming Comedy Special</li>
          <li>Best Streaming Reality Show or Competition Series</li>
          <li>Best Streaming Series, Drama</li>
          <li>Best Actor in a Streaming Series, Drama</li>
          <li>Best Actress in a Streaming Series, Drama</li>
          <li>Best Supporting Actor in a Streaming Series, Drama</li>
          <li>Best Supporting Actress in a Streaming Series, Drama</li>
          <li>Best Writing in a Streaming Series, Drama</li>
          <li>Best Directing in a Streaming Series, Drama</li>
          <li>Best Streaming Series, Comedy</li>
          <li>Best Actor in a Streaming Series, Comedy</li>
          <li>Best Actress in a Streaming Series, Comedy</li>
          <li>Best Supporting Actor in a Streaming Series, Comedy</li>
          <li>Best Supporting Actress in a Streaming Series, Comedy</li>
          <li>Best Writing in a Streaming Series, Comedy</li>
          <li>Best Directing in a Streaming Series, Comedy</li>
          <li>Best Streaming Limited or Anthology Series</li>
          <li>Best Streaming Movie</li>
          <li>Best Actor in a Streaming Limited or Anthology Series or Movie</li>
          <li>Best Actress in a Streaming Limited or Anthology Series or Movie</li>
          <li>Best Supporting Actor in a Streaming Limited or Anthology Series or Movie</li>
          <li>Best Supporting Actress in a Streaming Limited or Anthology Series or Movie</li>
          <li>Best Writing in a Streaming Limited or Anthology Series or Movie</li>
          <li>Best Directing in a Streaming Limited or Anthology Series or Movie</li>
        </ul>

        <h3 className='mt-5 mb-3'>Nominations Info:</h3>
        <p>The number of nominations will be based on the submissions received in each category. There can be a minimum of six nominees in each category and a maximum of ten.</p>

        <h3 className='mt-5 mb-3'>Streaming Platform Submission Info:</h3>
        <p>To submit as a series or movie, it must be original programming exclusive to the streaming platform. This content should not be available anywhere else.</p>

        <p>For example, shows that air on FX but are available the next day on Hulu do not qualify as streaming titles. Current streaming platforms that are eligible for nominations (more to be added as original/exclusive content is created on other streaming platforms):</p>

        <ul className='list-unstyled'>
          <li>AcornTV</li>
          <li>Allblk</li>
          <li>Amazon Prime</li>
          <li>AMC+</li>
          <li>Apple TV +</li>
          <li>BET+</li>
          <li>Crunchyroll</li>
          <li>Discovery +</li>
          <li>Disney +</li>
          <li>ESPN +</li>
          <li>Freevee</li>
          <li>HBO Max</li>
          <li>Hulu</li>
          <li>MGM+</li>
          <li>Netflix</li>
          <li>Paramount +</li>
          <li>Peacock</li>
          <li>Roku</li>
          <li>Shudder</li>
          <li>SundanceNow</li>
          <li>We TV+</li>
        </ul> */}

        <h3 className='mt-5 mb-3'>Submission Checklist:</h3>
        <ul>
          <li>Basic information about the selected entry.</li>
          <li>A brief plot description of the content being submitted.</li>
          <li>A promotional image from the series or movie. Headshots for any talent submissions.</li>
          <li>One video submission of the movie for consideration.</li>
          <li>Ways to submit videos:
            <ul>
              <li>Provide a digital link to access the screener.</li>
              <li>Provide an FYC app or website with all required login information.</li>
              <li>Video Quality: 720p, 1080p, or 4K.</li>
              <li>Video Format: MP4 or MOV.</li>
              <li>Audio Codec: Stereo or 5.1 Sound.</li>
              <li>The file size should be under 35GB.</li>
              <li>All video submissions should include beginning and end credits.</li>
              <li>Closed Captioning is preferred.</li>
            </ul>
          </li>
        </ul>

        <p className='mt-5'>Questions? Please email <a className='guidelines--link' href='mailto:events@HollywoodCreative.org'>events@HollywoodCreative.org</a>.</p>
      </Col>
    </Row>
  </Layout>
);

export default SubmissionGuidelinesPage