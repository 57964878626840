import React from 'react';
import { connect } from 'react-redux';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { 
  setEntryFilter, 
  setCategoryFilter, 
  sortByDateRecent, 
  sortByDateOldest, 
  sortByCategoryAZ, 
  sortByCategoryZA, 
  sortByEntryAZ, 
  sortByEntryZA,
  sortByUnpaid,
  sortByPaid
} from '../actions/filters';

export class AdminNominationListFilters extends React.Component {
  state = {
    searchType: 'entry'
  }
  handleSearchTypeChange = (e) => {
    switch (e.target.value) {
      case 'searchEntry':
        this.setState(() => ({ searchType: 'entry' }));
        break;
      case 'searchCategory':
        this.setState(() => ({ searchType: 'category' }));
        break;
    }
  };
  handleTextChange = (e) => {
    if (this.state.searchType === 'entry') {
      this.props.setEntryFilter(e.target.value);
    } else if (this.state.searchType === 'category') {
      this.props.setCategoryFilter(e.target.value);
    }
  };
  handleSortChange = (e) => {
    switch (e.target.value) {
      case 'recentDate':
        this.props.sortByDateRecent();
        break;
      case 'oldestDate':
        this.props.sortByDateOldest();
        break;
      case 'categoryAZ':
        this.props.sortByCategoryAZ();
        break;
      case 'categoryZA':
        this.props.sortByCategoryZA();
        break;
      case 'entryAZ':
        this.props.sortByEntryAZ();
        break;
      case 'entryZA':
        this.props.sortByEntryZA();
        break;
      case 'paidFalse':
        this.props.sortByUnpaid();
        break;
      case 'paidTrue':
        this.props.sortByPaid();
        break;
    }
  };
  render() {
    return (
      <Row>
        <Col className='nomination-list--filters'>
          <Row>
            <InputGroup as={Col} className='nomination-list--search-group'>
              <Form.Control 
                placeholder='Search'
                className='nomination-list--search-filter'
                type='text'
                defaultValue={this.props.filters.entry}
                onChange={this.handleTextChange}
              />
              <Form.Select 
                className='nomination-list--search-type-filter'
                onChange={this.handleSearchTypeChange}>
                <option value='searchEntry'>Entry</option>
                <option value='searchCategory'>Category</option>
              </Form.Select>
            </InputGroup>
            <InputGroup as={Col}>
              <Form.Label className='nomination-list--sort-filter-label'>Sort by:</Form.Label>
              <Form.Select  className='nomination-list--sort-filter' onChange={this.handleSortChange}>
                <option value='recentDate'>Most Recent</option>
                <option value='oldestDate'>Oldest</option>
                <option value='paidFalse'>Paid Status: In Cart</option>
                <option value='paidTrue'>Paid Status: Paid</option>
                <option value='categoryAZ'>Category: A-Z</option>
                <option value='categoryZA'>Category: Z-A</option>
                <option value='entryAZ'>Entry: A-Z</option>
                <option value='entryZA'>Entry: Z-A</option>
              </Form.Select>
            </InputGroup>
          </Row>
        </Col>
      </Row>
    )
  }
};

const mapStateToProps = (state) => ({
  filters: state.filters
});
const mapDispatchToProps = (dispatch) => ({
  setEntryFilter: (text) => dispatch(setEntryFilter(text)),
  setCategoryFilter: (text) => dispatch(setCategoryFilter(text)),
  sortByDateRecent: () => dispatch(sortByDateRecent()),
  sortByDateOldest: () => dispatch(sortByDateOldest()),
  sortByCategoryAZ: () => dispatch(sortByCategoryAZ()),
  sortByCategoryZA: () => dispatch(sortByCategoryZA()),
  sortByEntryAZ: () => dispatch(sortByEntryAZ()),
  sortByEntryZA: () => dispatch(sortByEntryZA()),
  sortByUnpaid: () => dispatch(sortByUnpaid()),
  sortByPaid: () => dispatch(sortByPaid())
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminNominationListFilters);